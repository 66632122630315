<template>
    <section>
        <tool-bar>
            Settings
        </tool-bar>
    </section>
</template>

<script>
    import ToolBar from '../layouts/ToolBar'

    export default {
        name: 'Settings',
        components: {
            ToolBar
        },
        data() {
            return {}
        },
        computed: {
            campaigns() {
                return this.$store.state.mall.campaigns
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
